.SearchPageCards {
  margin-bottom: 36.47px;

  .card-div {
    margin-bottom: 28px;
    cursor: pointer;
  }

  .title {
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 25px;
    font-family: "Poppins", sans-serif;
  }

  .view-all {
    float: right;
    font-weight: 400;
    font-size: 24px;
    cursor: pointer;
  }

  .card {
    border-radius: 6px;
    background: var(--white);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    min-height: 370px;
    border: none;

    .card-img-block {
      width: 100%;
      height: 184.37px;
      border-radius: 6px;
      overflow: hidden;

      .img-fluid {
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: fill;
      }
    }

    .card-body {
      padding: 16px;
      text-align: left;
    }
  }

  .card-name {
    font-weight: 600;
    font-size: 20px;
    color: var(--black);
    opacity: 0.63;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    text-transform: capitalize;
  }
  .no-data {
    max-width: 400px;
    width: 100%;
    height: 150px;
  }

  .location {
    font-weight: 400;
    font-size: 16px;
    color: var(--black);
    margin: 16px 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    text-transform: capitalize;
  }

  .amount {
    font-weight: 500;
    font-size: 16px;
    color: var(--black);
  }

  .date {
    font-weight: 500;
    font-size: 16px;
    color: var(--black);
    margin: 16px 0;
    text-transform: capitalize;
    opacity: 0.80;
  }

  @media screen and (max-width: 440px) {
    .title,
    .view-all {
      font-size: 18px;
    }
  }
}
