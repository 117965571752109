#TourBooking {
  .bannerbgimg {
    width: 100%;
    height: 55vh;
    object-fit: contain;
  }

  .viewlocation {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: var(--textlightblue);
    cursor: pointer;
  }

  .noofguest {
    font-weight: 500;
    font-size: 18px;
    line-height: 34px;
    color: var(--locationtext);
  }

  .gst {
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    color: var(--labeltext);
    opacity: 0.8;

    .form-check{
      line-height: 25px;
    }
  }

  .addition {
    font-weight: 500;
    font-size: 14px;
    line-height: 34px;
    color: var(--locationtext);
  }

  .dueamt {
    font-weight: 500;
    font-size: 18px;
    line-height: 34px;
    color: var(--labeltext);
    opacity: 0.8;
  }

  .dueamt1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 34px;
    color: var(--labeltext);
    opacity: 0.8;
  }

  .maintxt {
    font-weight: 500;
    font-size: 18px;
    line-height: 34px;
    color: var(--labeltext);
    opacity: 0.8;
  }

  .details-text-to {
    display: inline;
    font-weight: 500;
    font-size: 15px;
    line-height: 40px;
    margin-right: 10px;
  }

  .noseat {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .noseattext {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--labeltext);
  }

  .booking-details {
    margin: 61px 0px 32px;
    background: var(--white);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 15px 50px;
    min-height: 277px;

    .details {
      text-align: left;

      .event-name {
        font-weight: 500;
        font-size: 30px;
        line-height: 45px;
        color: var(--labeltext);
        text-transform: capitalize;
      }

      .topbanner {
        width: 100%;
        height: 55vh;
      }

      .reference-id {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: var(--labeltext);
      }

      .details-text {
        display: inline;
        font-weight: 400;
        font-size: 15px;
        line-height: 40px;
        margin-right: 10px;
      }

      .loc-text {
        font-weight: 400;
        font-size: 15px;
        line-height: 40px;
        margin: 0px;
        text-transform: capitalize;
      }

      .loc-icon {
        margin-right: 5px;
        margin-bottom: 4.5px;
        width: 18px;
      }

      .calendar-icon {
        width: 20px;
        margin-right: 5px;
        margin-bottom: 4.5px;
      }

      .clock-icon {
        width: 16px;
        margin-right: 5px;
        margin-bottom: 6px;
        margin-left: 10px;
      }

      hr {
        margin: 10px 0;
        border-top: 1px solid ;
        opacity: 1;
      }
    }
  }
  .booked-date {
    background-color: white;
    color: #999999;
    
    // padding: 1px;
    // border:1px solid var(--textdark);
}

  .select-dp {
    margin-bottom: 6px;

    .css-1pahdxg-control,
    .css-1s2u09g-control {
      height: 45px;
      // background: var(--filterinputbg);
      border: 1px solid var(--inputborder);
      border-radius: 6px;
      padding: 0 17.17px;
      box-shadow: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-6j8wv5-Input {
      margin: 0;
    }

    .css-1pndypt-Input {
      font-size: 14px;
      font-weight: 300;
      color: var(--black);
    }

    .css-14el2xx-placeholder {
      text-align: left;
      margin-left: 0px;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: var(--black);
    }

    .css-319lph-ValueContainer,
    .css-fke1ie-indicatorContainer {
      padding: 0;
    }

    .css-qc6sy-singleValue {
      font-size: 14px;
      font-weight: 300;
      color: var(--black);
      white-space: unset;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-word;
      margin: 0;
    }
    .react-datepicker__day--highlighted {
      background-color: red;
      color: white;
      border-radius: 50%;
      padding: 1px;
    }
    .departure-date{
    height: 45px;
    border-radius: 6px;
    padding: 0 17.17px;
    box-sizing: border-box;
    outline: none;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: var(--black);
    mix-blend-mode: normal;
    box-shadow: none;
    border: 1px solid var(--inputborder);
    background: var(--white)
    }

    .time{
      border: none;
      background: none;
    }
   
  }

  .payment-div {
    background: var(--white);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 20px 50px;
    text-align: left;
    margin: 61px 0px 32px;
    min-height: 277px;

    .e-name {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: var(--labeltext);
      margin-bottom: 8px;
      text-transform: capitalize;
    }

    .date-type {
      font-weight: 400;
      font-size: 18px;
      color: var(--labeltext);
    }

    .details-text {
      font-weight: 400;
      font-size: 15px;
      color: var(--locationtext);
      margin: 10px 0px;
    }

    .calendar-icon {
      width: 20px;
      margin-right: 5px;
      margin-bottom: 4.5px;
    }

    .clock-icon {
      width: 16px;
      margin-right: 5px;
      margin-bottom: 6px;
      margin-left: 10px;
    }

    .check-div {
      width: max-content;
      margin-top: 8px;

      .check-form {
        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: var(--labeltext);
        }
      }

      .form-check-input {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        border: 1px solid var(--btnbg);
        cursor: pointer;
      }

      .form-check-input:checked {
        background-color: var(--btnbg);
      }

      .form-check-input:focus {
        box-shadow: none;
      }

      .form-check {
        display: flex;
      }
    }

    .detail {
      font-weight: 500;
      font-size: 20px;
      line-height: 34px;
      color: var(--labeltext);
    }

    .btn-div {
      margin-top: 18px;

      .pay-btn {
        width: max-content;
      }
    }

    .input-div {
      display: flex;
      flex-wrap: wrap;

      label {
        margin-right: 8px;
      }
    }

    .counter {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      .counter_output {
        min-width: 41.6px;
        height: 32px;
        background: var(--white);
        border: 1px solid var(--border2);
        border-radius: 6px;
        text-align: center;
        line-height: 31px;
        font-weight: 300;
        font-size: 14px;
        color: var(--black);
        margin: 0 12.33px;
      }

      .CustomButton {
        .counter-btn {
          width: 30px;
          height: 30px;
          border-radius: 0;
          padding: 0;
        }
      }

      .btndisable {
        cursor: not-allowed;
      }
    }
  }

  .terms {
    background: var(--white);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 14px 28px 31px;
    text-align: left;
    margin-top: 32px;
    margin-bottom: 45px;

    .terms-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: var(--black);
      margin-bottom: 8px;
    }

    .desc {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: var(--labeltext);
      margin-bottom: 0;

      a {
        color: var(--textlightblue);
        text-decoration: none;
      }
    }
  }

  .no-ticket {
    display: flex;
    align-items: center;
  }

  .price-block {
    padding-bottom: 10px;

    .title {
      color: var(--locationtext);
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }

    .price {
      color: var(--locationtext);
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
    }
  }

  .coupon-section {
    margin-top: 10px;

    .coupon-div {
      display: flex;
      flex-wrap: wrap;

      .InputField .input,
      .InputField .coupon-field.input {
        max-width: 210px;
        padding-right: 17.17px;
        margin-right: 20px;
        margin-bottom: 10px;
      }

      .apply-btn .CustomButton .buttonmain {
        width: max-content;
        margin-bottom: 10px;
      }

      .apply-btn .CustomButton .buttonmain:disabled {
        cursor: not-allowed;
        background: var(--btnbg);
      }
    }

    .amount-div {
      margin-bottom: 10px;

      .amount-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 34px;
        color: var(--labeltext);
        margin-bottom: 6px;
      }

      .discount-text {
        opacity: 0.8;
      }
    }

    .errorText {
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
      color: red;
      margin-top: -5px;
      margin-bottom: 5px;
    }
  }

  .BookingForm {
    margin-top: -65px;
  }
.bookform2 .BookingForm{
  margin-top: 0px;
}
  @media screen and (max-width: 1365px) {
    .BookingForm {
      margin-top: -120px;
    }
    .bookform2 .BookingForm{
      margin-top: 0px;
    }
  }

  @media screen and (max-width: 1267px) {
    .BookingForm {
      margin-top: -80px;
    }
    .bookform2 .BookingForm{
      margin-top: 0px;
    }
  }

  @media (max-width: 1200px) and (min-width: 991px) {
    .payment-div .check-div .form-check {
      width: 70%;
    }
  }

  @media screen and (max-width: 991px) {
    .booking-details .counter-section {
      padding-left: 0;
    }

    .booking-details .details-div {
      border-right: none;
    }

    .booking-details .counter-div {
      border-top: 1px solid var(--border2);
      margin-top: 1rem;
      padding-top: 1rem;
    }

    .booking-details .details {
      padding-right: 0px;
    }

    .BookingForm {
      margin-top: 0px;
      margin-bottom: 32px;
      padding: 20px 50px;
    }

    .payment-div {
      margin-top: 0px;
    }
  }

  @media screen and (max-width: 768px) {

    .booking-details,
    .payment-div,
    .BookingForm {
      padding-left: 35px;
      padding-right: 35px;
    }

    .booking-details .details .event-name {
      margin-top: 5px;
      font-size: 22px;
      line-height: unset;
    }

    .bannerbgimg{
      height: 100%;
    }

    .booking-details{
      margin-top: 30px;
    }
  }

  // @media screen and (max-width: 440px) {
  //   .bannerbgimg {
  //     height: 100%;
  //   }
  // }
}