#comingsoon {
  .input,
  .form-control.input {
    max-width: 524px;
    margin: auto;
    height: 58px;
    padding: 0 32px;
    border-radius: 9px;
  }

  .buttonmain {
    margin: 22px;
    font-weight: 500;
    font-size: 22px;
    border-radius: 9px;
    width: unset;
    height: 52.15px;
    background: var(--btnbg1);
  }

  .navlogo {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 120px;
    margin: 5px 15px;
  }
  .bannerbg {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 110vh;
  }
  .textwrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 12%);
    width: 100%;
    padding: 0px 40px;
  }

  .logo {
    width: 100%;
    max-width: 420px;
  }

  .title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 35px;
    color: var(--title);
  }

  .subtitle {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 25px;
    color: var(--title);
    margin-top: 10px;
  }

  .becomepartner {
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: var(--white);
    margin-top: 15px;
  }

  .inputwrapper {
    margin-top: 20px;
  }

  .coming {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 56px;
    color: var(--btnbg);
  }

  .footertext {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 23px;
    color: var(--footertext);
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .productby {
    font-family: "Ubuntu";
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: var(--footertext);
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .footerimg {
    margin-left: 15px;
  }

  @media screen and (min-width: 1400px) {
    .navlogo {
      max-width: 135px;
    }
  }

  @media screen and (max-width: 992px) {
    .textwrapper {
      transform: translate(-50%, 10%);
      padding: 0px 10px;
    }

    .logo {
      width: 180px;
    }

    .title {
      font-size: 24px;
    }

    .subtitle {
      font-size: 18px;
    }

    .becomepartner {
      font-size: 28px;
      margin-top: -10px;
    }

    .coming {
      font-size: 36px;
    }

    .footertext {
      margin-top: 25px;
    }

    .productby {
      margin-top: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    .footertext {
      font-size: 22px;
    }

    .footerimg {
      margin-left: 10px;
    }
  }
}
