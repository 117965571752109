.editprofileform {
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  // border-radius: 6px;
  // text-align: left;
  // padding: 12px 32px;

  .form-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--black);
    margin-bottom: 10px;
  }
  .textboxes {
    display: flex;
  }
  .form-input {
    margin-bottom: 18px;
    text-align: left;

    label {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: var(--labeltext);
      margin-bottom: 10px;
    }
    .InputField {
      margin-right: 12px;
    }
    .InputField .input,
    .InputField .form-control.input,
    .InputField textarea.form-control.input {
      font-size: 14px;
      font-weight: 300;
      color: var(--black);
      // background: var(--filterinputbg);
      border-radius: 6px;
      // border: none;
      font-family: "Poppins", sans-serif;
    }

    ::placeholder {
      font-size: 14px;
      font-weight: 300;
      color: var(--black);
    }

    .date-field {
      text-transform: uppercase;
    }
  }
  .emailtxt {
    .InputField .input,
    .InputField .form-control.input,
    .InputField textarea.form-control.input {
      background: var(--disableinput);
      cursor: not-allowed;
    }
  }
  .select-dp {
    .css-1pahdxg-control,
    .css-1s2u09g-control {
      height: 45px;
      // background: var(--filterinputbg);
      border: 1px solid var(--inputborder);
      border-radius: 6px;
      padding: 0 17.17px;
      box-shadow: none;
      margin-right: 12px;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-6j8wv5-Input {
      margin: 0;
    }

    .css-14el2xx-placeholder {
      text-align: left;
      margin-left: 0px;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: var(--black);
    }

    .css-319lph-ValueContainer,
    .css-fke1ie-indicatorContainer {
      padding: 0;
    }

    .css-qc6sy-singleValue {
      white-space: unset;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-word;
      margin: 0;
    }
  }
  @media screen and (max-width: 992px) {
    .textboxes {
      display: block;
    }
    .form-input {
      .InputField {
        margin-right: 0px;
      }
    }
  }
}
