@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --black: #000000;
  --white: #ffffff;
  --textblue: #1910b0;
  --textgreen: #03b22a;
  --textorng: #eb8d20;
  --textlightblue: #308add;
  --nav-border: #e6dcdc;
  --dot: #ff8b03;
  --navtext1: #00000080;
  --navtext2: #000000b3;
  --navtext3: #00000099;
  --inputborder: #cbcbcb;
  --inputbg: #f2f2f2;
  --inputtext: #757575;
  --btnbg: #38a7b6;
  --btnbg1: #39a8b7;
  --disabled: #8f8f8f;
  --footertext: #a3a7ab;
  --title: #262626;
  --footerborder: #20202026;
  --textdark: #202020;
  --footertext: #202020cc;
  --searchinput: #fffefe;
  --searchinputborder: #00000066;
  --rangetext: #fffdfd;
  --error: #dc3545;
  --linktext: #007bff;
  --labeltext: #1b1a1a;
  --filterinputbg: #f7f7f7;
  --border: #9f9d9d;
  --locationtext: #5c5c5c;
  --border2: #9f9d9d80;
  --yellow: #fece09;
  --failedbg: #ff3737;
  --successbg: #56cc47;
  --pageborder: #dfe3e8;
  --pageicon: #c4cdd5;
  --disabledbg: #919eab;
  --disableinput: #e6e7e8;
  --border3: #cdcaca;
  --bgloader: #ffffffe0;
}

html {
  height: auto;
  width: 100%;
}

body,
#root {
  margin: 0;
  padding: 0 !important;
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: auto;
  text-align: center;
  box-sizing: border-box;
  overflow-x: hidden;
}

a {
  color: var(--textblue);
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: var(--textblue);
  text-decoration: none;
}

.closebtn {
  position: absolute;
  right: 12.83px;
  top: 13.83px;
  cursor: pointer;
  width: 23.33px;
  height: 23.33px;
}

@media (min-width: 576px) {
  #root .container {
    max-width: 95%;
  }
}

@media (min-width: 768px) {
  #root .container {
    max-width: 95%;
  }
}

@media (min-width: 992px) {
  #root .container {
    max-width: 95%;
  }
}

@media (min-width: 1200px) {
  #root .container {
    max-width: 95%;
  }
}

@media (min-width: 1400px) {
  #root .container {
    max-width: 95%;
  }
}

.profileImg {
  position: relative;

  /* transition: transform 0.2s; */
  /* width: 100px !important; */
  outline: 1px solid rgb(124, 121, 121);
}

.profileImg:hover {
  /* transform: scale(1.1); */
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 1px !important;
}

.multi {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Textnooverflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dis:hover {
  background-color: white !important;
  border-color: var(--textlightblue) !important;
  border-width: 1px;
  border: 1px solid var(--textlightblue) !important;
}

.MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;
  margin-top: -7px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 8.5px 4px;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bgloader);
  z-index: 9999999;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.preloader.hide {
  opacity: 0;
  display: none;
}

.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 75px;
  display: inline-block;
  vertical-align: middle;
}

.loader-1 .loader-outter {
  position: absolute;
  border: 4px solid #1a0508;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.loader-1 .loader-inner {
  position: absolute;
  border: 4px solid var(--textlightblue);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

@-webkit-keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.form-control:focus {
  color: unset;
  background-color: unset;
  border-color: unset;
  outline: none;
  box-shadow: none;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--borderdark);
}

.dropdown-item {
  color: var(--textdark);
  font-size: 16px;
  text-align: center;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--textdark);
  background-color: var(--textlighter);
  text-decoration: none;
}

.dropdown-item.hover,
.dropdown-item:hover {
  color: var(--textdark);
  background-color: var(--textlighter);
  text-decoration: none;
}

.dropdown-menu {
  background-color: var(--white);
}

.dropdown-toggle::after {
  display: none;
  margin-left: none;
  vertical-align: none;
  content: "";
  border-top: none;
  border-right: none;
  border-bottom: 0;
  border-left: none;
}

// pagination start
.paginationsection {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationallignment {
  display: inline-block;
  // float: right;
}

.pagination {
  margin-bottom: 0px;
}

.pagination>li {
  display: inline;
  margin-left: 8px;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  text-decoration: none;
  margin-left: -1px;
  font-size: 14px;
  font-weight: 700;
  background: var(--white);
  border: 1px solid var(--pageborder);
  border-radius: 4px;
  height: 32px;
  width: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  background: var(--white);
  border: 1px solid var(--pageborder);
  box-sizing: border-box;
  border-radius: 4px;
}

// .pagination>li:first-child>a,
// .pagination>li:first-child>span {
//   svg {
//     path {
//       // fill: var(--lightgrey) !important;
//     }
//   }
// }

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  background: var(--white);
  border: 1px solid var(--pageborder);
  box-sizing: border-box;
  border-radius: 4px;
}

// .pagination>li:last-child>a,
// .pagination>li:last-child>span {
//   svg {
//     path {
//       //fill: var(--lightgrey) !important;
//     }
//   }
// }

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  z-index: 3;
  background: var(--white);
  border: 1px solid var(--btnbg);
  box-sizing: border-box;
  color: var(--btnbg);
  border-radius: 4px;
  cursor: pointer;
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
  box-sizing: border-box;
  border-radius: 4px;
  cursor: not-allowed;
  border: 1px solid var(--disabledbg);
  background: var(--disabledbg);
  opacity: 0.5;
}

.pagination-lg>li>a,
.pagination-lg>li>span {
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.3333333;
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.pagination-sm>li>a,
.pagination-sm>li>span {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.paginationicon {
  path {
    fill: var(--pageicon);
  }
}

@media screen and (max-width: 576px) {
  .paginationsection {
    flex-direction: column;
  }

  .noofpagetxt {
    margin-bottom: 10px;
  }
}

// pagination end
.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  color: var(--textdark);
  background-color: var(--white);
  border-color: var(--white);
  box-shadow: unset;
  border: var(--white);
}

#button-tooltip {
  z-index: 1000;
}

// .Toastify__progress-bar {
//   display: none;
// }
.dropdown-menu.show {
  display: block;
  width: 100%;
  border-radius: 1px;
}

@media (max-width: 556px) {

  .pagination>li:first-child>a,
  .pagination>li:first-child>span {
    margin-right: 20px;
  }

  .pagination>li:last-child>a,
  .pagination>li:last-child>span {
    margin-left: 20px;
  }
}

@media (max-width: 390px) {

  .pagination>li:first-child>a,
  .pagination>li:first-child>span {
    margin-right: 10px;
  }

  .pagination>li:last-child>a,
  .pagination>li:last-child>span {
    margin-left: 10px;
  }

  .pagination>li>a,
  .pagination>li>span {
    padding: 3px 10px;
  }
}

.daterangepicker {
  color: var(--textdark);
  background-color: var(--bgwhite);
}

.daterangepicker .calendar-table table {
  color: var(--textdark);
  background-color: var(--bgfaintwhite);
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: transparent;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  color: var(--textdark);
  background-color: var(--bgfaintwhite);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--primary);
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: var(--primary);
  border-color: transparent;
  color: var(--white);
}

.daterangepicker:after {
  border-bottom: 6px solid var(--textlight);
}

.daterangepicker .drp-buttons .btn {
  background-color: transparent;
  border: 1px solid var(--primary);
  border-color: var(--primary);
  color: var(--texrdark);
  border-radius: 8px;
}

.daterangepicker .calendar-table {
  background-color: var(--bgwhite);
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border: solid var(--textdarker);
  color: #fff;

  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .drp-calendar.left .calendar-table {
  border: 1px solid var(--borderdark);
}

.daterangepicker .drp-calendar.right .calendar-table {
  border: 1px solid var(--borderdark);
}

@media (min-width: 564px) {
  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: 1px solid var(--borderdark);
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: 1px solid var(--borderdark);
  }
}

@media (max-width: 564px) {
  .daterangepicker .drp-selected {
    padding-right: 0px;
  }

  .daterangepicker .drp-calendar.right {
    padding: 0px 0px 8px 8px;
  }
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  font-weight: 500 !important;
  text-align: center !important;
  color: var(--btnbg) !important;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--navtext1);
  font-weight: 400;
  text-align: center;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.form-control.is-valid,
.was-validated .form-control:valid, .form-control.is-invalid, .was-validated .form-control:invalid {
  border: 1px solid var(--inputborder);
  padding: 0 17.17px;
  background-image: none;
}

.form-control.is-valid,
.was-validated .form-control:valid:focus, .form-control.is-invalid, .was-validated .form-control:invalid:focus{
  border: 1px solid var(--inputborder);
  box-shadow: none;
}
.form-check-input:focus{
  box-shadow: none;
}