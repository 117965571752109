.InputField {
  position: relative;

  .form-control:focus {
    border-color: var(--inputborder);
    box-shadow: none;
    margin: none;
  }

  .input,
  .form-control.input {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    padding: 0 17.17px;
    box-sizing: border-box;
    outline: none;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: var(--black);
    mix-blend-mode: normal;
    box-shadow: none;
    border: 1px solid var(--inputborder);
    background: var(--white);
  }

  input::placeholder {
    font-size: 16px;
    font-weight: 300;
    color: var(--black);
  }

  .password-control.input {
    padding-right: 50px;
  }

  .invalid-feedback {
    font-family: "Poppins", sans-serif;
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    font-weight: 400;
    color: var(--error);
  }

  textarea.form-control.input {
    height: auto;
    width: 100%;
    border-radius: 15px;
    padding: 15px;
    box-sizing: border-box;
    outline: none;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    mix-blend-mode: normal;
    box-shadow: none;
    text-transform: unset;
  }

  .passwordIcon {
    position: absolute;
    right: 20px;
    bottom: 12.5px;
    width: 20px;
    cursor: pointer;
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
}