.HomepageCards {
  margin-bottom: 20px;

  .card-div {
    margin-bottom: 28px;
    cursor: pointer;
  }

  .title {
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 25px;
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .view-all {
    float: right;
    font-weight: 400;
    font-size: 24px;
    cursor: pointer;
    white-space: nowrap;
  }

  .card {
    border-radius: 6px;
    background: var(--white);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    min-height: 320px;
    border: none;

    .card-img-block {
      width: 100%;
      height: 100%;
      max-height: 171.88px;
      border-radius: 6px;
      overflow: hidden;

      .img-fluid {
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: fill;
      }
    }

    .card-body {
      padding: 16px;
      text-align: left;
    }
  }

  .card-name {
    font-weight: 600;
    font-size: 20px;
    color: var(--black);
    opacity: 0.63;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  .date {
    font-weight: 400;
    font-size: 16px;
    color: var(--black);
    margin: 16px 0;
  }

  .amount {
    font-weight: 500;
    font-size: 16px;
    color: var(--black);
  }

  @media screen and (max-width: 440px) {
    .title,
    .view-all {
      font-size: 17px;
    }
  }
}
